import React from 'react'

export default () => (
    <section className="ds ms page_copyright section_padding_50" style={{ backgroundColor: '#15191f' }}>
        <div className="container" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
            <div className="row">
                <div className="col-sm-12 text-center">
                    <p style={{ color: '#d2d2d2', marginBottom: '0' }}>&copy; Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
                    <div className="social-links">
                        <a className="social-icon border-icon rounded-icon" href="https://www.instagram.com/cannabashog/" rel="noreferrer" title="Instagram" target="_blank">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a className="social-icon border-icon rounded-icon" href="https://twitter.com/ashleysalzman/" rel="noreferrer" title="Twitter" target="_blank">
                            <i className="fab fa-twitter"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
)