import React from 'react'
import get from 'lodash/get'
import flatten from 'lodash/flatten'
import { useStaticQuery, graphql } from "gatsby"

export default () => {
    const data = useStaticQuery(graphql`
        query SidebarQuery {
            allContentfulBlogPost(sort: { fields: [publishDate], order: DESC } filter: {node_locale: {eq: "en-US"}}) {
                edges {
                  node {
                    id
                    tags
                  }
                }
              }
              allContentfulCategory {
                edges {
                  node {
                    id
                    slug
                    title
                    parent {
                      id
                    }
                    children {
                      id
                    }
                  }
                }
              }
              allInstaNode {
                edges {
                  node {
                    id
                    likes
                    comments
                    mediaType
                    preview
                    original
                    timestamp
                    caption
                    localFile {
                      childImageSharp {
                        fixed(width: 150, height: 150) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                    # Only available with the public api scraper
                    thumbnails {
                      src
                      config_width
                      config_height
                    }
                    dimensions {
                      height
                      width
                    }
                  }
                }
              }
        }
    `)

    const posts = get(data, 'allContentfulBlogPost.edges')
    const categoriesList = data.allContentfulCategory ? get(data, 'allContentfulCategory.edges') : []
    const categories = categoriesList.length ? 
        categoriesList.map(({ node: { id, slug, title } }) => ({ id, slug, title }))
            .reduce((unique, item) => unique.includes(
                unique.find(({ slug, title }) => slug === item.slug && title === item.slug)
            ) ? unique : [...unique, item], []) : categoriesList
    const instaFeed = get(data, 'allInstaNode.edges')
    const tagsList = flatten(posts.map(({ node: { tags }}) => tags.map(tag => tag))).reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);
    const tags = tagsList ? tagsList : [];

    return (
        <div className="col-lg-4">
            <div className="sidebar_wrap">
            {/* <div className="sidebar_widget search_widget">
                <div className="widget_title">
                <h4>Search</h4>
                </div>
                <form>
                <input type="text" className="form-control" placeholder="Search your keyword..." />
                <button type="submit" className="search_btn"><i className="fa fa-search"></i></button>
                </form>
            </div> */}

            <div className="sidebar_widget instagram_widget">
                <div className="widget_title">
                <h4>Instagram</h4>
                </div>
                <ul>
                {instaFeed.map(({ node }) => (
                    <li key={node.id}>
                    <a href={`https://www.instagram.com/p/${node.id}`} target="_blank" rel="noreferrer">
                        <img src={node.thumbnails.find(({ config_width }) => config_width === 240).src} alt={node.caption} />
                    </a>
                    </li>
                ))}
                </ul>
            </div>

            {/* <div className="sidebar_widget followus_widget">
                <div className="widget_title">
                <h4>Follow Us</h4>
                </div>
                <ul>
                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                <li><a href="#"><i className="fab fa-pinterest"></i></a></li>
                </ul>
            </div> */}

            <div className="sidebar_widget categories_widget">
                <div className="widget_title">
                <h4>Categories</h4>
                </div>
                <ul>
                {categories.map(({ id, slug, title }) => (
                    <li key={id}>
                    <a href="#">{title}</a>
                    </li>
                ))}
                </ul>
            </div>

            {/* <div className="sidebar_widget popular_widget">
                <div className="widget_title">
                <h4>Feeds</h4>
                </div>
                <ul>
                <li>
                    <div className="post_thumb">
                    <a href="#"><img src="https://via.placeholder.com/80x60" alt="image" /></a>
                    </div>
                    <h6><a href="#">Alonso Kelina Falao Asiano Pero</a></h6>
                    <p><i className="fa fa-clock-o"></i> 6 Hours ago</p>
                </li>
                <li>
                    <div className="post_thumb">
                    <a href="#"><img src="https://via.placeholder.com/80x60" alt="image" /></a>
                    </div>
                    <h6><a href="#">It is a long established fact that a reader</a></h6>
                    <p><i className="fa fa-clock-o"></i> 6 Hours ago</p>
                </li>
                <li>
                    <div className="post_thumb">
                    <a href="#"><img src="https://via.placeholder.com/80x60" alt="image" /></a>
                    </div>
                    <h6><a href="#">Many desktop publish packages and web</a></h6>
                    <p><i className="fa fa-clock-o"></i> 6 Hours ago</p>
                </li>
                <li>
                    <div className="post_thumb">
                    <a href="#"><img src="https://via.placeholder.com/80x60" alt="image" /></a>
                    </div>
                    <h6><a href="#">Alonso Kelina Falao Asiano Pero</a></h6>
                    <p><i className="fa fa-clock-o"></i> 6 Hours ago</p>
                </li>
                <li>
                    <div className="post_thumb">
                    <a href="#"><img src="https://via.placeholder.com/80x60" alt="image" /></a>
                    </div>
                    <h6><a href="#">Photo booth anim 8-bit PBR 3 wolf moon.</a></h6>
                    <p><i className="fa fa-clock-o"></i> 6 Hours ago</p>
                </li>
                </ul>
            </div> */}

            <div className="sidebar_widget">
                <div className="widget_title">
                <h4>Tags</h4>
                </div>
                <div className="tag_cloud">
                {tags.map((tag, index) => (
                    <a key={index} href="#">{tag}</a>
                ))}
                </div>
            </div>
            </div>
        </div>
    );
}