import React from 'react'
import { Link } from 'gatsby'
// import styles from './navigation.module.css'

// <nav role="navigation">
  //   <ul className={styles.navigation}>
  //     <li className={styles.navigationItem}>
  //       <Link to="/">Home</Link>
  //     </li>
  //     <li className={styles.navigationItem}>
  //       <Link to="/blog/">Blog</Link>
  //     </li>
  //   </ul>
  // </nav>

export default () => (
  <header className="main-header wd-weefly-nav">
    <div className="wd-wrpr">
      <div className="container">
        <div className="wd-wrpr-inner">
          <div className="wd-left wd-logo">
            <Link to="/">
              <img src="/logo@2x.png" alt="Pot Reviews: Northwest Strain Reviews" />
            </Link>
          </div>
          {/* <div className="wd-left">

            <div id="nav-toggle-label">
              <div id="hamburger">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div id="cross">
                <span></span>
                <span></span>
              </div>
            </div>
            <ul className="wd-menu">
              <li className="has-child has-mega-menu">
                <a href="#">Home</a>
                <div className="mega-menu">
                  <div className="container">
                    <div className="mega-sub-menu menu-seperater col col-3">
                      <h6>Demos</h6>
                      <ul className="nav nav-pills nav-tabs-menu flex-column" id="main-nav-tabs">
                        <li className="nav-link active"><a href="#tab-1" data-toggle="tab">weefly</a></li>
                        <li className="nav-link"><a href="#tab-2" data-toggle="tab">Recreational</a></li>
                        <li className="nav-link"><a href="#tab-3" data-toggle="tab">Weedo-Main</a></li>
                        <li className="nav-link"><a href="#tab-4" data-toggle="tab">Medicana</a></li>
                        <li className="nav-link"><a href="#tab-5" data-toggle="tab">Shop Extended</a></li>
                        <li className="nav-link"><a href="#tab-6" data-toggle="tab">cannafarm</a></li>
                        <li className="nav-link"><a href="#tab-7" data-toggle="tab">cannavest</a></li>
                      </ul>
                    </div>
                    <div className="tab-content well col col-9">
                      <div className="tab-pane active" id="tab-1">
                        <div className="row">
                          <div className="mega-sub-menu col col-4">
                            <h6>weefly Pages</h6>
                            <ul>
                              <li><a href="index.html">Homepage One</a></li>
                              <li><a href="homepage-2.html">Homepage Two</a></li>
                              <li><a href="about.html">About Us</a></li>
                              <li><a href="contact.html">Contact</a></li>
                              <li><a href="404.html">404 Page</a></li>
                              <li><a href="cart.html">Cart</a></li>
                              <li><a href="account.html">Account</a></li>
                              <li><a href="checkout.html">Checkout</a></li>
                              <li><a href="login.html">Login</a></li>
                              <li><a href="signup.html">Logout</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-4">
                            <h6>weefly Blog</h6>
                            <ul>
                              <li><a href="blog-two-col.html">Blog Two Col</a></li>
                              <li><a href="blog-left-sidebar.html">Blog Left Sidebar</a></li>
                              <li><a href="blog-right-sidebar.html">Blog Right Sidebar</a></li>
                              <li><a href="blog-details.html">Blog Details</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-4">
                            <h6>weefly Shop</h6>
                            <ul>
                              <li><a href="shop.html">Shop Full Width</a></li>
                              <li><a href="shop-left-sidebar.html">Shop Left Sidebar</a></li>
                              <li><a href="shop-right-sidebar.html">Shop Right Sidebar</a></li>
                              <li><a href="shop-details.html">Shop Details</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-2">
                        <div className="row">
                          <div className="mega-sub-menu col col-4">
                            <h6>Recreational Pages</h6>
                            <ul>
                              <li><a href="recreational/index.html">Homepage</a></li>
                              <li><a href="recreational/comparison.html">Comparison</a></li>
                              <li><a href="recreational/contact.html">Contact</a></li>
                              <li><a href="recreational/404.html">404 Page</a></li>
                              <li><a href="recreational/login.html">Login</a></li>
                              <li><a href="recreational/signup.html">Logout</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-4">
                            <h6>Recreational Blog</h6>
                            <ul>
                              <li><a href="recreational/blog.html">Blog Archive</a></li>
                              <li><a href="recreational/blog-with-left-sidebar.html">Blog Left Sidebar</a></li>
                              <li><a href="recreational/blog-with-right-sidebar.html">Blog Right Sidebar</a></li>
                              <li><a href="recreational/blog-detail-with-right-sidebar.html">Blog Details Right Sidebar</a></li>
                              <li><a href="recreational/blog-detail-with-left-sidebar.html">Blog Details Left Sidebar</a></li>
                              <li><a href="recreational/blog-detail.html">Blog Details</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-4">
                            <h6>Recreational Shop</h6>
                            <ul>
                              <li><a href="recreational/shop.html">Shop Full Width</a></li>
                              <li><a href="recreational/shop-detail.html">Shop Details</a></li>
                              <li><a href="recreational/shop-left-sidebar.html">Shop Left Sidebar</a></li>
                              <li><a href="recreational/shop-right-sidebar.html">Shop Right Sidebar</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-3">
                        <div className="row">
                          <div className="mega-sub-menu col col-4">
                            <h6>Weedo-Main Pages</h6>
                            <ul>
                              <li><a href="weedo-main/index.html">Homepage</a></li>
                              <li><a href="weedo-main/about-us.html">About</a></li>
                              <li><a href="weedo-main/contact.html">Contact</a></li>
                              <li><a href="weedo-main/404.html">404 Page</a></li>
                              <li><a href="weedo-main/comparison.html">Comparison</a></li>
                              <li><a href="weedo-main/pricing-plans.html">Pricing Plans</a></li>
                              <li><a href="weedo-main/coming-soon.html">Coming Soon</a></li>
                              <li><a href="weedo-main/login.html">Login</a></li>
                              <li><a href="weedo-main/signup.html">Logout</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-4">
                            <h6>Weedo-Main Blog</h6>
                            <ul>
                              <li><a href="weedo-main/blog-archive.html">Blog Archive</a></li>
                              <li><a href="weedo-main/blog-left-sidebar.html">Blog Left Sidebar</a></li>
                              <li><a href="weedo-main/blog-right-sidebar.html">Blog Right Sidebar</a></li>
                              <li><a href="weedo-main/blog-detail-left.html">Blog Details Left</a></li>
                              <li><a href="weedo-main/blog-detail-right.html">Blog Details Right</a></li>
                              <li><a href="weedo-main/blog-detail.html">Blog Details Full</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-4">
                            <h6>Weedo-Main Shop</h6>
                            <ul>
                              <li><a href="weedo-main/shop.html">Shop Main</a></li>
                              <li><a href="weedo-main/shop-details.html">Shop Details</a></li>
                              <li><a href="weedo-main/shop-full.html">Shop Full Width</a></li>
                              <li><a href="weedo-main/shop-left.html">Shop Left Sidebar</a></li>
                              <li><a href="weedo-main/shop-right.html">Shop Right Sidebar</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-4">
                        <div className="row">
                          <div className="mega-sub-menu col col-4">
                            <h6>Medicana Pages</h6>
                            <ul>
                              <li><a href="medical/index.html">Homepage</a></li>
                              <li><a href="medical/contact.html">Contact</a></li>
                              <li><a href="medical/404.html">404 Page</a></li>
                              <li><a href="medical/login.html">Login</a></li>
                              <li><a href="medical/signup.html">Logout</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-4">
                            <h6>Medicana Blog</h6>
                            <ul>
                              <li><a href="medical/blog-archive.html">Blog Archive</a></li>
                              <li><a href="medical/blog-left-sidebar.html">Blog Left Sidebar</a></li>
                              <li><a href="medical/blog-right-sidebar.html">Blog Right Sidebar</a></li>
                              <li><a href="medical/blog-detail.html">Blog Details</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-4">
                            <h6>Medicana Shop</h6>
                            <ul>
                              <li><a href="medical/shop.html">Shop Main</a></li>
                              <li><a href="medical/shop-details.html">Shop Details</a></li>
                              <li><a href="medical/shop-full.html">Shop Full Width</a></li>
                              <li><a href="medical/shop-left.html">Shop Left Sidebar</a></li>
                              <li><a href="medical/shop-right.html">Shop Right Sidebar</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-5">
                        <div className="row">
                          <div className="mega-sub-menu col col-6">
                            <h6>Shop Extended Pages</h6>
                            <ul>
                              <li><a href="shop-extended/index.html">Homepage One</a></li>
                              <li><a href="shop-extended/homepage-2.html">Homepage Two</a></li>
                              <li><a href="shop-extended/shop-comparison.html">Comparison</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-6">
                            <h6>Shop Extended Shop</h6>
                            <ul>
                              <li><a href="shop-extended/shop-detail.html">Shop Details</a></li>
                              <li><a href="shop-extended/cart.html">Cart</a></li>
                              <li><a href="shop-extended/checkout.html">Checkout</a></li>
                              <li><a href="shop-extended/account.html">Account</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-6">
                        <div className="row">
                          <div className="mega-sub-menu col col-4">
                            <h6>Cannafarm Pages</h6>
                            <ul>
                              <li><a href="cannafarm/index.html">Homepage</a></li>
                              <li><a href="cannafarm/contact.html">Contact</a></li>
                              <li><a href="cannafarm/investor.html">Investor</a></li>
                              <li><a href="cannafarm/team.html">Team</a></li>
                              <li><a href="cannafarm/process.html">Process</a></li>
                              <li><a href="cannafarm/login.html">Login</a></li>
                              <li><a href="cannafarm/signup.html">Logout</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-4">
                            <h6>Cannafarm Blog</h6>
                            <ul>
                              <li><a href="cannafarm/blog.html">Blog Page</a></li>
                              <li><a href="cannafarm/blog-detail.html">Blog Details</a></li>
                            </ul>
                          </div>
                          <div className="mega-sub-menu col col-4">
                            <h6>Cannafarm Shop</h6>
                            <ul>
                              <li><a href="cannafarm/shop.html">Shop Main</a></li>
                              <li><a href="cannafarm/shop-detail.html">Shop Details</a></li>
                              <li><a href="cannafarm/shopping-cart.html">Shop Cart</a></li>
                              <li><a href="cannafarm/checkout.html">Shop Checkout</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-7">
                        <div className="row">
                          <div className="mega-sub-menu col col-6 offset-col-6">
                            <h6>Cannavest Pages</h6>
                            <ul>
                              <li><a href="cannavest/index.html">Homepage</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="has-child">
                <a href="#">Blog</a>
                <ul className="sub-menu">
                  <li><a href="blog-two-col.html">Blog Two Column</a></li>
                  <li><a href="blog-left-sidebar.html">Blog Left Sidebar</a></li>
                  <li><a href="blog-right-sidebar.html">Blog Right Sidebar</a></li>
                  <li><a href="blog-details.html">Blog Details</a></li>
                </ul>
              </li>
              <li className="has-child">
                <a href="#">Shop</a>
                <ul className="sub-menu">
                  <li><a href="shop.html">Shop</a></li>
                  <li><a href="shop-left-sidebar.html">Shop Left Sidebar</a></li>
                  <li><a href="shop-right-sidebar.html">Shop Right Sidebar</a></li>
                  <li><a href="shop-details.html">Shop Details</a></li>
                </ul>
              </li>
            </ul>
          </div> */}
          <div className="wd-center" />
          <div className="wd-right" />
          {/* <div className="wd-right">
            <ul className="wd-menu">
              <li><a href="about.html">About us</a></li>
              <li><a href="contact.html">Contact Us</a></li>
              <li className="wd-cart">
                <a href="#"><i className="flaticon-online-shopping-cart"><span className="cart-item">0</span></i></a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
    {/* <div className="mobile-menu">
      <ul className="wd-menu pop-scroll">
        <li className="has-child">
          <a href="#">Home</a>
          <ul className="sub-menu">
            <li><a href="index.html">Homepage 1</a></li>
            <li><a href="homepage-2.html">Homepage 2</a></li>
            <li><a href="recreational/index.html">Recreational Home</a></li>
            <li><a href="weedo-main/index.html">Weedo-Main</a></li>
            <li><a href="medical/index.html">Medical</a></li>
            <li><a href="shop-extended/index.html">Shop Extended</a></li>
          </ul>
        </li>
        <li className="has-child">
          <a href="#">Blog</a>
          <ul className="sub-menu">
            <li><a href="blog-two-col.html">Blog Two Column</a></li>
            <li><a href="blog-left-sidebar.html">Blog Left Sidebar</a></li>
            <li><a href="blog-right-sidebar.html">Blog Right Sidebar</a></li>
            <li><a href="blog-details.html">Blog Details</a></li>
          </ul>
        </li>
        <li className="has-child">
          <a href="#">Shop</a>
          <ul className="sub-menu">
            <li><a href="shop.html">Shop</a></li>
            <li><a href="shop-left-sidebar.html">Shop Left Sidebar</a></li>
            <li><a href="shop-right-sidebar.html">Shop Right Sidebar</a></li>
            <li><a href="shop-details.html">Shop Details</a></li>
          </ul>
        </li>
        <li><a href="about.html">About us</a></li>
        <li><a href="contact.html">contact us</a></li>
      </ul>
    </div> */}
  </header>
)
