import React from 'react';
import Img from 'gatsby-image';

export default ({ heroImage, profileImage, isoDate, publishDate, slug, tags, strain }) => (
    <div className="item-media-wrap">
      <div className="item-media entry-thumbnail">
        <Img alt="" fluid={heroImage.fluid} />
      </div>
      {strain && strain.trim().length && (
        <div className="strain-wrapper">
            <p>{strain}</p>
        </div>
      )}
      <div className="entry-meta ds content-justify">
        <div className="inline-content big-spacing small-text darklinks"> 
          <span className="meta">
            <i className="fa fa-calendar highlight rightpadding_5" aria-hidden="true"></i>
            <a href="#">
              <time dateTime={isoDate}>{publishDate}</time>
            </a>
          </span>
          <span className="meta">
            <i className="fa fa-tags highlight rightpadding_5" aria-hidden="true"></i>
            {tags.map((tag, index) => (
              <a key={`${slug}-${index}`} href="#" className="tag">{tag}</a>
            ))}
          </span> 
        </div>
        <div>
          <a href="#" className="post-author">
            <Img alt="" fixed={profileImage.fixed} />
          </a>
        </div>
      </div>
    </div>
);